























import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'

@Component
export default class SelectField extends AbstractField {}
