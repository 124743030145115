












import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import HttpMethod from '@/shared/configs/http-method.config'
import Field from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'

@Component({
  components: { PageHeader, Form }
})
export default class CreateClientDialog extends Vue {
  @Prop() meta!: any

  form: FormBase = new FormBase()
    .setEndpoint('clients')
    .setFields([
      new Field()
        .setKey('name')
        .setTitle('Name'),
      new Field()
        .setKey('description')
        .setTitle('Description'),
    ])
    .setSubmit(false)

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async submitForm() {
    const { form }: any = this.$refs

    const errors = await form.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }
}
