
















import { Component, Prop, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user'
import http from '@/shared/http'
import ResponseInterface from '@/shared/interfaces/response.interface'
import Client from '@/shared/modules/client/client'
import PageHeader from '@/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Form from '@/components/form/Form.vue'
import SelectFieldBase from '@/shared/classes/form/fields/select/select-field'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import SelectField from '@/components/form/fields/SelectField.vue'
import _ from 'lodash'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Table from '@/components/data-table/Table.vue'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'

@Component({
  components: { Table, SelectField, Form, PageHeader }
})
export default class AssignUsersToClient extends Vue {
  @Prop() meta!: any

  assignedUsers: User[] = []
  form: FormBase = new FormBase
  table: DataTableBase = new DataTableBase()
    .setData([])
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTitle('Name'),
      new DataTableHeader()
        .setKey('email')
        .setTitle('Email'),
    ])
    .setPagination(false)

  created() {
    this.loadAndSetAssignedUsers()
  }

  async loadAndSetAssignedUsers() {
    const assignedUsers = await http.get(`clients/${ this.meta.client.uuid }`)
      .then((response: ResponseInterface<Client[]>) => response.data.users.map((user: User) => user))

    this.table.setData(assignedUsers)

    this.assignedUsers = await http.get(`users?filter-notIn-uuid=${ assignedUsers.map((user: User) => user.uuid).join(',') }`)
      .then((response: ResponseInterface<User[]>) => response.data.data)

    this.form = this.form
      .setFields([
        new SelectFieldBase()
          .setTitle('Select user')
          .setKey('selected_user')
          .setItems(this.assignedUsers.map((user: User) => new SelectItem().setValue(user.uuid).setTitle(user.name)))
      ])
      .setSubmit(false)

    this.form.setData({ selected_user: null })
  }

  assignUser() {
    const selectedUserUuid = _.get(this.form, 'data.selected_user')

    http.post(`clients/${ this.meta.client.uuid }/assign-user`, {
      user_uuid: selectedUserUuid,
    })
      .then(() => {
        this.loadAndSetAssignedUsers()
        this.meta.onSuccess && this.meta.onSuccess()
      })
  }

  get isUserSelected(): boolean {
    return ! _.get(this.form, 'data.selected_user')
  }
}
