












import { Component, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import DataTable from '@/components/data-table/DataTable.vue'
import DataTableAction from '@/shared/classes/data-table/data-table-action'
import EditAction from '@/components/data-table/default-actions/edit-action'
import Client from '@/shared/modules/client/client'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import AssignUsersToClient from '@/components/clients/AssignUsersToClient.vue'
import EditClientDialog from '@/components/clients/EditClientDialog.vue'
import CreateClientDialog from '@/components/clients/CreateClientDialog.vue'
import { Permission } from '@/shared/configs/permission.config'

@Component({
  components: { DataTable }
})
export default class Clients extends Vue {
  table = new DataTableBase()
    .setEndpoint(`clients`)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTitle('Name'),
      new DataTableHeader()
        .setKey('description')
        .setTitle('Description'),
    ])
    .setActions([
      new DataTableAction()
        .setAction(this.assignUsersToClient)
        .setTitle('Assign users')
        .setIcon('mdi-account-multiple-plus-outline')
        .setPermissions([Permission.clients.assignUser]),
      new EditAction()
        .setAction(this.openEditDialog),
    ])

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 800,
      component: CreateClientDialog,
      meta: {
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'User assignment was successful',
          })

          this.tableRef.refresh()
        }
      }
    })
  }

  openEditDialog(client: Client) {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 800,
      component: EditClientDialog,
      meta: {
        client,
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'User assignment was successful',
          })

          this.tableRef.refresh()
        }
      }
    })
  }

  assignUsersToClient(client: Client) {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 800,
      component: AssignUsersToClient,
      meta: {
        client,
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Successfully assigned user to the client',
          })
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
